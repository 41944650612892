import React, { useState } from "react";
import './ForgotpassPopup.css';
import close from '../../assets/close.svg';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";

function ForgotPassPopup({ onClose }) {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [fadeOut, setFadeOut] = useState(false);
    const [removeInput, setRemoveInput] = useState(false);

    const handleResetPassword = async () => {
        if (email) {
            try {
                setFadeOut(true);
                setTimeout(() => {
                    setRemoveInput(true);
                }, 600);
                setTimeout(async () => {
                    await sendPasswordResetEmail(auth, email);
                    setMessage('Password reset email sent! Check your inbox.');
                }, 1000);
                
            } catch (error) {
                setMessage(error.message);
            }
        } else {
            setMessage('Please enter your email.');
        }
    };

    return (
        <div className="forgotpass-popup-overlay" onClick={(e) => e.target === e.currentTarget && onClose()}>
            <div className="forgotpass-popup-content">
                <button className="close-button" onClick={onClose}>
                    <img src={close} alt="Close" />
                </button>
                <p className="forgotpass-popup-title">
                    Forgot Password
                </p>
                <p className="forgotpass-popup-warning">
                    {message || 'Enter your email to reset your password.'}
                </p>
                <div className={`fade-elements ${fadeOut ? 'fade-out' : ''}`}>
                    {!removeInput && 
                    <>
                    <input
                        type="email"
                        className="email-input"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="forgotpass-popup-buttons">
                        <button className="forgotpass-button reset" onClick={handleResetPassword}>
                            Reset Password
                        </button>
                        <button className="forgotpass-button cancel" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                    </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ForgotPassPopup;
