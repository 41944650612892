import React from 'react';
import {DoroChatProvider} from '../context/DoroChatContext';
import DoroChat from '../components/dorochat/DoroChat';
import MetaTags from 'react-meta-tags';

const DoroChatView = () => {
    return (
        <DoroChatProvider>
            <MetaTags>
                <title>Doro Chat - Doro: Mental Health Progress & Support</title>
                <meta name="description" content="Chat with Doro, an AI trained in mental health, to discuss your mental state and receive personalized suggestions and guidance in real time." />
                <link rel="canonical" href="https://doro.razroze.ca/doro-test-start" />
            </MetaTags>
            <DoroChat />
        </DoroChatProvider>
    );
};

export default DoroChatView;