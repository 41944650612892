import { useEffect, useState, useContext } from 'react';
import { Button } from '@mui/material';
import ExitIcon from '@mui/icons-material/Clear';
import { theme } from '../../layout/Theme';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import { LayoutContext } from '../../context/LayoutContext';
import '../../styles/DoroChat.css';

const EndChatButton = ({ onClick }) => {
  const { windowSize } = useWindowSizeListener();
  const { themeMode } = useContext(LayoutContext);
  const [position, setPosition] = useState({});

  useEffect(() => {
    if (windowSize.deviceType === 'mobile') {
      setPosition({
        right: '2%',
        top: '2%',
      });
    } else if (windowSize.deviceType === 'tablet') {
      setPosition({
        right: '2%',
        top: '2%',
      });
    } else {
      setPosition({
        right: '10%',
        top: '11.5%',
      });
    }
  }, [windowSize]);

  return (
    windowSize.deviceType === 'large' ? (
      <Button
        className='buttonStyle'
        variant='outlined'
        onClick={onClick}
        sx={{
          borderRadius: '15px',
          height: '45px !important',
          color: `${themeMode === 'dark' ? theme.palette.scrollbarC : theme.palette.lightGray} !important`,
          backgroundColor: `${themeMode === 'dark' ? theme.palette.lightGray : theme.palette.scrollbarC} !important`,
          borderColor: `${themeMode === 'dark' ? theme.palette.lightGray : theme.palette.scrollbarC} !important`,
          '&:hover': {
            backgroundColor: 'transparent !important',
            color: `${themeMode === 'dark' ? theme.palette.darkGray : theme.palette.scrollbarC} !important`,
          },
          '&:active': {
            backgroundColor: 'transparent !important',
            color: `${themeMode === 'dark' ? theme.palette.darkGray : theme.palette.scrollbarC} !important`,
          },
        }}
      >
        End
      </Button>
    ) : (
      <Button
        color='inherit'
        className='buttonStyle'
        variant='outlined'
        sx={{
          position: 'absolute',
          borderRadius: '15px',
          color: theme.palette.white,
          right: position.right,
          top: position.top,
          border: 0,
          backgroundColor: 'transparent',
          marginBottom: '3em',
          '&:hover, &:active': {
            backgroundColor: 'transparent !important',
            color: `${theme.palette.white80} !important`,
          },
          zIndex: 1000,
        }}
        onClick={onClick}
      >
        <ExitIcon style={{ transform: 'scale(1.6)' }} />
      </Button>
    )
  );
};

export default EndChatButton;
