import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axiosInstance from "../../utils/axiosConfig";
import Footer from "../Footer";
import ReactMarkdown from "react-markdown";
import { Snackbar, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import getFirebaseIdToken from '../../Firebase/getFirebaseIdToken';
import "../../styles/QuestionnairePage.css";
import MetaTags from 'react-meta-tags';

const fetchQuestionnaireData = async (id) => {
  const { data } = await axiosInstance.get(`/ptests/${id}/info`);
  return data;
};

const fetchQuestions = async (id) => {
  const { data } = await axiosInstance.get(`/ptests/${id}/questions`);
  return data;
};

const QuestionnairePage = () => {
  const { title, id } = useParams();
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState(null);
  const [tokenValid, setTokenValid] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [resultData, setResultData] = useState(null);
  const [animateResultBox, setAnimateResultBox] = useState(false);
  const resultBoxRef = useRef(null);

  //To check if questionnaire gets blurred
  const tokenCheck = async () => {
    const idToken = await getFirebaseIdToken(); 

    if (idToken) {
      setTokenValid(true); 
    } else {
      setTokenValid(false);
    }
  };

  useEffect(() => {
    tokenCheck();
    window.scrollTo(0, 0);
  }, []);

  const questionsClass = tokenValid ? "" : "blurred-content";

  useEffect(() => {
    if (resultData && resultBoxRef.current) {
      resultBoxRef.current.scrollIntoView({ behavior: "smooth" });
      setAnimateResultBox(true);
    }
  }, [resultData]);

  const {
    data: questionnaire,
    error,
    isError,
    isLoading,
  } = useQuery(["questionnaire", id], () => fetchQuestionnaireData(id), {
    staleTime: Infinity,
    cacheTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const fetchAdditionalInfo = useCallback(async () => {
    if (questionnaire) {
      try {
        const questions = await fetchQuestions(id);
        setQuestions(questions);
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    }
  }, [questionnaire, id]);

  useEffect(() => {
    fetchAdditionalInfo();
  }, [fetchAdditionalInfo]);

  // Handle loading state
  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  // Handle error state
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const handleOptionChange = (questionId, answerIndex) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answerIndex,
    }));
  };

  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const renderInfoBox = (title, content) => (
    <div className="questionnaire-box">
      <div className="questionnaire-content">
        <h2 className="basic-info-title">{title}</h2>
        {content}
      </div>
    </div>
  );

  const basicInfoContent = questionnaire ? (
    <>
      <p style={{ fontSize: "18px" }}>
        <ReactMarkdown>
          {"**Description:** " + questionnaire?.description}
        </ReactMarkdown>
      </p>
      <p style={{ fontSize: "18px" }}>
        <strong>Statements:</strong> {questionnaire.statements}
      </p>
      <p style={{ fontSize: "18px" }}>
        <strong>Duration:</strong> {questionnaire.duration}
      </p>
      <p style={{ fontSize: "14px", fontStyle: "italic" }}>
        <strong>Authors:</strong> {questionnaire.authors}
      </p>
      <p style={{ fontSize: "14px", fontStyle: "italic" }}>
        <strong>Reference:</strong> {questionnaire.reference}
      </p>
    </>
  ) : null;

  const instructionContent = (
    <>
      <p style={{ fontSize: "18px" }}>
        <ReactMarkdown>{questionnaire?.instructions}</ReactMarkdown>
      </p>
    </>
  );

  const scoringContent = (
    <p style={{ fontSize: "18px" }}>
      <ReactMarkdown>{questionnaire?.scoring}</ReactMarkdown>
    </p>
  );

  const questionsContent = questions?.map((question, index) => (
    <div className={`question ${questionsClass}`} key={question.id}>
      <p>
        {index + 1}. {question.question_text}
      </p>
      {question.answer_labels.map((option, optionIndex) => (
        <label key={optionIndex}>
          <input
            type="radio"
            name={`question-${question.id}`}
            value={optionIndex}
            checked={answers[question.id] === optionIndex}
            onChange={() => handleOptionChange(question.id, optionIndex)}
          />
          {option}
        </label>
      ))}
    </div>
  ));

  const renderResultBox = (title, score, details) => (
    <div
      className={`questionnaire-box ${animateResultBox ? "fade-in" : ""}`}
      ref={resultBoxRef}
    >
      <div className="questionnaire-content">
        <h2 className="basic-info-title">{title}</h2>
        <p>
          Score:{" "}
          <strong>
            {score} / {totalScore}
          </strong>
        </p>
        <p>
          Details: <strong>{details}</strong>
        </p>
        <p style={{ fontSize: "14px", lineHeight: "2", paddingBottom: "10px" }}>
          <em>
            These questionnaires aim to provide insights and assess susceptibility, not to diagnose. Completing more similar questionnaires can refine their accuracy in assessing susceptibility, but they <b>should not be considered diagnostic</b>.
          </em>
        </p>
        <button className="start-button" style={{ width: "20%", padding: "10px 10px", margin: "0 auto", display: "block" }}>
          {" "}
          <Link
            to="/doro-test-start"
            style={{ textDecoration: "none", color: "inherit", fontSize: "1.7vh" }}
          >
            Talk with Doro
          </Link>
        </button>
      </div>
    </div>
  );

  const handleSubmit = async () => {
    if (Object.keys(answers).length !== questions.length) {
      handleOpenSnackbar("Please answer all questions.");
      return;
    }

    const score = questionnaire?.max_score;
    setTotalScore(score);

    let question_dict = {}
    for (let i = 0; i < questions.length; i++) {
      question_dict[questions[i].id] = questions[i];
    }
    console.log(question_dict);

    const formattedAnswers = Object.keys(answers).map(key => {
      const question = question_dict[key];
      const answerIndex = answers[key];
    
      if (question && question.valid_answers[answerIndex] !== undefined) {
        return {
          qid: parseInt(key, 10),
          response: question.valid_answers[answerIndex]
        };
      } else {
        return null;
      }
    })

    const idToken = await getFirebaseIdToken();
    try {
      const response = await axiosInstance.post(
        `/users/me/ptests/${id}/responses`,
        formattedAnswers,
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      );
      setResultData(response.data);
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

  const canonicalUrl = `https://doro.razroze.ca/questionnaire/${title}/${id}`;

  return (
    <div className="container">
      <MetaTags>
        <title>{questionnaire.name} - Doro Mental Health Assessment</title>
        <meta
          name="description"
          content={`Complete the ${questionnaire.name} to assess your mental status. Track your progress and gain insights with Doro.`}
        />
        <link rel="canonical" href={canonicalUrl} />
      </MetaTags>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="header-section">
        <div className="title-container">
          {/* Conditional rendering to ensure questionnaire is not null */}
          {questionnaire && (
            <h1 className="page-title">{questionnaire.name}</h1>
          )}
        </div>
      </div>
      <div className="styling-image-1"></div>
      <div className="questionnaire-section">
        <div className="styling-image-2"></div>
        {renderInfoBox("Basic Information", basicInfoContent)}
        {renderInfoBox("Instruction", instructionContent)}
        {renderInfoBox("Scoring", scoringContent)}
        {!tokenValid && (
          <>
            <button className="button" style={{ backgroundColor: "transparent" }}>
              To gain access please have your first session with Doro
            </button>
            <button className="start-button">
              {" "}
              <Link
                to="/get-started"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Get Started
              </Link>
            </button>
          </>
        )}
        <div
          className="take-test-card"
          style={{
            "--dynamic-title": `"${title}"`,
            marginTop: window.innerWidth < 1030 ? tokenValid ? "20%" : "20%" : tokenValid ? "5%" : "8%",
          }}
        >
          <p className="question">{questionsContent}</p>
        </div>
        {tokenValid && !resultData ? (
          <button className="start-button" onClick={handleSubmit}>
            SUBMIT
          </button>
        ) : null}
        {resultData &&
          renderResultBox("Result", resultData.score, resultData.label)}
      </div>
      <Footer />
    </div>
  );
};

export default QuestionnairePage;
