import React, { useContext } from 'react';
import { Button, Stack} from '@mui/material';
import { LayoutContext } from '../../context/LayoutContext.js';
import { DoroChatContext } from '../../context/DoroChatContext.js';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import { theme } from '../../layout/Theme';
import '../../styles/DoroChat.css';

const PauseButton = () => {
  const {themeMode} = useContext(LayoutContext);
  const {handleOnClickPause} = useContext(DoroChatContext);
  const {windowSize} = useWindowSizeListener();

  const handlePause = () => {
    handleOnClickPause();
    console.log('Pause button clicked');
  };

  return (
      <Button
      onClick={handlePause}
      className='buttonStyle'
      sx={{
          position: 'fixed',
          left: windowSize.deviceType != 'mobile' ? '60px' : '20px',
          marginTop: '38px',
          color: themeMode === 'dark' ? '#FFFFFF' : '#484F59', 
          fontSize: '14em',
          backgroundColor: 'transparent',
          border: themeMode === 'dark' ? '1px solid #FFFFFF !important' : '1px solid #484F59 !important',
          borderRadius: '15px',
          padding: windowSize.deviceType != 'mobile' ? '7px' : '6px',
          zIndex: 3000,
          fontSize: windowSize.deviceType != 'mobile' ? theme.fontSize.smaller : '12px',
          '&:hover, &:active': {
          backgroundColor: 'transparent',
          border: themeMode === 'dark' ? '1px solid #FFFFFF80 !important' : '1px solid #484F5980 !important',
          color: themeMode === 'dark' ? '#FFFFFF80' : '#484F5980',
          }
      }}
      >
          Pause
      </Button>
  );
};

export default PauseButton;