
export const quotes = [
    { author: "Nikos Kazantzakis", quote: "Since we cannot change reality, let us change the eyes which see reality." },
    { author: "James Baldwin", quote: "Not everything that is faced can be changed, but nothing can be changed until it is faced." },
    { author: "Virginia Woolf", quote: "Lock up your libraries if you like; but there is no gate, no lock, no bolt that you can set upon the freedom of my mind." },
    { author: "Albert Camus", quote: "In the depth of winter, I finally learned that within me there lay an invincible summer." },
    { author: "Maya Angelou", quote: "You may not control all the events that happen to you, but you can decide not to be reduced by them." },
    { author: "Anais Nin", quote: "Life shrinks or expands in proportion to one's courage." },
    { author: "George Orwell", quote: "Happiness can exist only in acceptance." },
    { author: "Simone de Beauvoir", quote: "Change your life today. Don't gamble on the future, act now, without delay." },
    { author: "Marcel Proust", quote: "Let us be grateful to people who make us happy; they are the charming gardeners who make our souls blossom." },
    { author: "Carl Jung", quote: "I am not what happened to me, I am what I choose to become." },
    { author: "Audre Lorde", quote: "I am deliberate and afraid of nothing." },
    { author: "Hermann Hesse", quote: "Some of us think holding on makes us strong, but sometimes it is letting go." },
    { author: "Susan Sontag", quote: "I haven't been everywhere, but it's on my list." },
    { author: "Khalil Gibran", quote: "Out of suffering have emerged the strongest souls; the most massive characters are seared with scars." },
    { author: "Fyodor Dostoevsky", quote: "The secret of man's being is not only to live but to have something to live for." },
    { author: "Ralph Waldo Emerson", quote: "What lies behind us and what lies before us are tiny matters compared to what lies within us." },
    { author: "Nietzsche", quote: "He who has a why to live can bear almost any how." },
    { author: "Margaret Atwood", quote: "A word after a word after a word is power." },
    { author: "Zora Neale Hurston", quote: "There are years that ask questions and years that answer." },
    { author: "Joseph Campbell", quote: "The cave you fear to enter holds the treasure you seek." },
    { author: "Victor Frankl", quote: "When we are no longer able to change a situation, we are challenged to change ourselves." },
    { author: "Thomas Merton", quote: "Art enables us to find ourselves and lose ourselves at the same time." },
    { author: "Haruki Murakami", quote: "Pain is inevitable. Suffering is optional." },
    { author: "Gloria Anzaldúa", quote: "I change myself, I change the world." },
    { author: "Albert Einstein", quote: "A person who never made a mistake never tried anything new." },
    { author: "Toni Morrison", quote: "You wanna fly, you got to give up the stuff that weighs you down." },
    { author: "Samuel Beckett", quote: "Ever tried. Ever failed. No matter. Try again. Fail again. Fail better." },
    { author: "Antoine de Saint-Exupéry", quote: "What makes the desert beautiful is that somewhere it hides a well." },
    { author: "Joan Didion", quote: "Life changes in the instant. The ordinary instant." },
    { author: "Octavio Paz", quote: "Deserve your dream." },
    { author: "Oliver Sacks", quote: "In examining disease, we gain wisdom about anatomy and physiology and biology. In examining the person with disease, we gain wisdom about life." },
    { author: "John Steinbeck", quote: "And now that you don't have to be perfect, you can be good." },
    { author: "G.K. Chesterton", quote: "An inconvenience is only an adventure wrongly considered." },
    { author: "Leonardo da Vinci", quote: "Time stays long enough for anyone who will use it." },
    { author: "Plato", quote: "We can easily forgive a child who is afraid of the dark; the real tragedy of life is when men are afraid of the light." },
    { author: "Virginia Satir", quote: "We must not allow other people’s limited perceptions to define us." },
    { author: "Italo Calvino", quote: "The more enlightened our houses are, the more their walls ooze ghosts." },
    { author: "Bertrand Russell", quote: "Do not fear to be eccentric in opinion, for every opinion now accepted was once eccentric." },
    { author: "Arthur Schopenhauer", quote: "Talent hits a target no one else can hit; Genius hits a target no one else can see." },
    { author: "Marie Curie", quote: "Be less curious about people and more curious about ideas." },
    { author: "Lao Tzu", quote: "Being deeply loved by someone gives you strength, while loving someone deeply gives you courage." },
    { author: "Emily Dickinson", quote: "Forever is composed of nows." },
    { author: "Walt Whitman", quote: "Keep your face always toward the sunshine - and shadows will fall behind you." },
    { author: "Henry David Thoreau", quote: "It's not what you look at that matters, it's what you see." },
    { author: "Eleanor Roosevelt", quote: "You must do the thing you think you cannot do." },
    { author: "Charles Bukowski", quote: "Sometimes you climb out of bed in the morning and you think, I'm not going to make it, but you laugh inside — remembering all the times you've felt that way." },
    { author: "Pablo Neruda", quote: "You can cut all the flowers but you cannot keep spring from coming." },
    { author: "Anaïs Nin", quote: "Life is a process of becoming, a combination of states we have to go through. Where people fail is that they wish to elect a state and remain in it. This is a kind of death." },
    { author: "Oscar Wilde", quote: "To live is the rarest thing in the world. Most people exist, that is all." },
    { author: "Rainer Maria Rilke", quote: "The only journey is the one within." },
    { author: "Rumi", quote: "The wound is the place where the Light enters you." },
    { author: "William James", quote: "The greatest weapon against stress is our ability to choose one thought over another." },
    { author: "Henry Ford", quote: "Whether you think you can, or you think you can't — you're right." },
    { author: "Aristotle", quote: "Quality is not an act, it is a habit." },
    { author: "Maya Angelou", quote: "I've learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel." },
];
