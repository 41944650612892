import { useMemo, useEffect, useState, useContext, useRef } from "react";
import { DoroChatContext } from "../context/DoroChatContext";
const MicRecorder = require('mic-recorder-to-mp3');
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [strbase64, setStrbase64] = useState("");
  const { sendMessage } = useContext(DoroChatContext);
  const recorderStarted = useRef(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (isRecording) {
      Mp3Recorder.start()
        .then(() => {
          recorderStarted.current = true;
        })
        .catch((e) => {
          console.error('Error starting recording:', e);
        });
    } else {
      if (recorderStarted.current) {
        Mp3Recorder.stop()
          .getMp3()
          .then(([buffer, blob]) => {
            // Convert blob to base64
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64data = reader.result;
              setStrbase64(base64data);
            };
          })
          .catch((e) => {
            console.error('Error stopping recording:', e);
          })
          .finally(() => {
            recorderStarted.current = false;
          });
      }
    }
  }, [isRecording]);

  useEffect(() => {
    if (strbase64) {
      sendMessage(strbase64, "audio");
    }
  }, [strbase64]);

  return useMemo(
    () => ({
      strbase64,
      isRecording,
      setIsRecording,
    }),
    [strbase64, isRecording, setIsRecording]
  );
};

export default useRecorder;