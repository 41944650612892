import React, { useState, useEffect } from "react";
import {theme} from '../layout/Theme';
import {Box, Stack, Typography, styled, Button} from '@mui/material';
import PrivacyPolicyPopup from './PrivacyPolicyPopup.js';
import DisclaimerPopup from './DisclaimerPopup.js';

const StyledFooterBox = styled(Box)({
  position: 'relative',
  display: 'flex',
  width: '100%',
  color: theme.palette.white,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#728094',
  zIndex: 10,
  padding: '1% 0',
});

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openPrivecyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 900);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <StyledFooterBox>
      {
        openPrivecyPolicy && (
          <PrivacyPolicyPopup handleClose={() => setOpenPrivacyPolicy(false)} />
        )
      }

      {
        openDisclaimer && (
          <DisclaimerPopup handleClose={() => setOpenDisclaimer(false)} />
        )
      }
      <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' alignItems='center' sx={{width: '100%', height: '100%'}}>
        
        <Typography
          sx={{
            fontSize: theme.fontSize.smaller,
            fontWeight: theme.fontWeight.small,
            color: '#F8FBFFDF',
            ml: isMobile ? '0%' : '10%'}}>
          Razroze Inc. © 2022-2024
        </Typography>

        <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' mr={isMobile ? '0%' : '10%'}>
          <Button
            variant='text'
            component="a"
            onClick={() => setOpenPrivacyPolicy(true)}
            disableRipple
            sx={{
              color: theme.palette.lightGray2,
              fontWeight: theme.fontWeight.medium,
              border: 'none',
              outline: 'none',
              fontSize: theme.fontSize.smaller,
              cursor: 'pointer',
              textTransform: 'none',
              '&:hover': {
                color: theme.palette.lightGray2,
              },
              '&:active': {
                color: theme.palette.lightGray2,
              },
            }}
            >
              Privacy Policy
          </Button>

          <Button
            variant='text'
            component="a"
            onClick={() => setOpenDisclaimer(true)}
            disableRipple
            sx={{
              color: theme.palette.lightGray2,
              fontWeight: theme.fontWeight.medium,
              border: 'none',
              outline: 'none',
              fontSize: theme.fontSize.smaller,
              cursor: 'pointer',
              textTransform: 'none',
              '&:hover': {
                color: theme.palette.lightGray2,
              },
              '&:active': {
                color: theme.palette.lightGray2,
              },
              width: '230px'
            }}
            >
              Disclaimer
          </Button>

          <Button
            variant='text'
            component="a"
            href="mailto:info@razroze.ca"
            disableRipple
            sx={{
              color: theme.palette.lightGray2,
              fontWeight: theme.fontWeight.medium,
              border: 'none',
              outline: 'none',
              fontSize: theme.fontSize.smaller,
              cursor: 'pointer',
              textTransform: 'none',
              '&:hover': {
                color: theme.palette.lightGray2,
              },
              '&:active': {
                color: theme.palette.lightGray2,
              },
            }}
            >
              Contact us
          </Button>
        </Stack>
      </Stack>
    </StyledFooterBox>
  );
};


export default Footer;
