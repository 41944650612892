import React, { useContext } from 'react';
import { Button, Box} from '@mui/material';
import Brightness7Icon from '@material-ui/icons/Brightness7'; 
import Brightness4Icon from '@material-ui/icons/Brightness3';
import { LayoutContext } from '../../context/LayoutContext.js';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import '../../styles/DoroChat.css';

const ThemeSwitch = () => {
  const { themeMode, toggleTheme } = useContext(LayoutContext);
  const {windowSize} = useWindowSizeListener();

  return (
      <Box>
        <Button
        onClick={toggleTheme}
        className='buttonStyle'
        sx={{
            position: 'fixed',
            right: windowSize.deviceType != 'mobile' ? '7px' : '-2px',
            color: themeMode === 'dark' ? '#FFFFFF' : '#484F59', 
            fontSize: '14em',
            backgroundColor: 'transparent',
            transform: windowSize.deviceType != 'mobile' ? 'scale(1.2)' : 'scale(0.9)',
            border: themeMode === 'dark' ? '1px solid #FFFFFF !important' : '1px solid #484F59 !important',
            marginRight: windowSize.deviceType != 'mobile' ? '60px' : '20px',
            borderRadius: '15px',
            zIndex: 3000,
            '&:hover, &:active': {
            backgroundColor: 'transparent',
            border: themeMode === 'dark' ? '1px solid #FFFFFF80 !important' : '1px solid #484F5980 !important',
            color: themeMode === 'dark' ? '#FFFFFF80' : '#484F5980',
            }
        }}
        >
            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </Button>
      </Box>
  );
};

export default ThemeSwitch;