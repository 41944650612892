import React, {useEffect, useRef, useState, createContext, useMemo, useContext} from 'react';
import axiosInstance from "../utils/axiosConfig";
import useWebSocket from "../hooks/useWebSocket";
import useTermsAndConditions from "../hooks/useTermsAndConditions";
import { LayoutContext } from "./LayoutContext";
import getFirebaseIdToken from '../Firebase/getFirebaseIdToken';
import { useNavigate } from "react-router-dom";
import { SessionContext } from './SessionContext';

const DoroChatContext = createContext(null); 

const DoroChatProvider = ({children}) => {
    const navigate = useNavigate();
    const {isChatStarted, setIsChatStarted, setHasChatEnded} = useContext(LayoutContext);
    const {setIsChatPaused, } = useContext(SessionContext);
    const [messages, setMessages] = useState([]);
    const [remainingSessions, setRemainingSessions] = useState(null);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
    const chatContainerRef = useRef(null);
    const textBoxRef = useRef(null);
    const [showQuote, setShowQuote] = useState(false);
    const { sendMessage, startSession, timer, progress, isConnectionError, sessionID } = useWebSocket(isChatStarted, setMessages, setInputDisabled, setHasChatEnded);
    const [showTerms, handleAgree] = useTermsAndConditions();

    useEffect(() => {
      const fetchUserData = async () => {
        const idToken = await getFirebaseIdToken();
    
        if (!idToken) {
          console.error("Failed to retrieve idToken or no user is authenticated.");
          navigate('/get-started');
          return;
        }
  
        try {
          const response = await axiosInstance.get(`/users/me/remaining-sessions`, {
            headers: {
              Authorization: `${idToken}`,
            },
          });
          setRemainingSessions(response.data);
        } catch (error) {
          console.error('Failed to fetch remaining sessions:', error);
        }
      };
    
      fetchUserData();
    
    }, [navigate]);

    useEffect(() => {
      if (!isChatStarted) {
        setMessages([]);
      }
    }, [isChatStarted]);

    useEffect(() => {
      if (isChatStarted && 
        (!messages.length || (messages.length <= 1 && messages[messages.length - 1].id === "typing-indicator"))) {
        setShowQuote(true);
      } else {
        setShowQuote(false);
      }
    }, [messages, isChatStarted]);

    const handleOnClickSend = () => {
      sendMessage(textBoxRef.current.value, 'text');
      textBoxRef.current.value = '';
      setInputDisabled(prev => !prev);
    };

    const handleOnClickPause = () => {
      setIsChatStarted(false);
      setIsChatPaused(true);
      sendMessage('', '', 'PAUSE');
    };

    const handleOnClickStart = () => {
      startSession();
      setIsChatStarted(true);
      setMessages(() => [{
        id: "typing-indicator",
        text: "",
        role: "assistant",
        class: "typing-indicator"
      }]);
    };

    const handleOnClickResume = () => {
      startSession(true);
      setIsChatStarted(true);
    };
  
    const value = useMemo(() => {
      return {
        showQuote,
        chatContainerRef,
        remainingSessions,
        messages,
        sendMessage,
        timer,
        progress,
        isConnectionError,
        sessionID,
        inputDisabled,
        setInputDisabled,
        sendButtonDisabled,
        setSendButtonDisabled,
        showTerms,
        handleAgree,
        handleOnClickStart,
        handleOnClickSend,
        handleOnClickPause,
        handleOnClickResume,
        textBoxRef,
      };
    }, [
      showQuote,
      chatContainerRef,
      remainingSessions,
      messages,
      sendMessage,
      timer,
      progress,
      isConnectionError,
      sessionID,
      inputDisabled,
      setInputDisabled,
      sendButtonDisabled,
      setSendButtonDisabled,
      showTerms,
      handleAgree,
      handleOnClickStart,
      handleOnClickSend,
      handleOnClickPause,
      handleOnClickResume,
      textBoxRef,
    ]);

    return (
        <DoroChatContext.Provider value={value}>
            {children}
        </DoroChatContext.Provider>
    );
};

export {DoroChatProvider, DoroChatContext};
