import React, { useEffect, useState, useContext, useRef } from 'react';
import '../../styles/BreathingAnimation.css';
import { LayoutContext } from '../../context/LayoutContext';

function BreathingAnimationComponent() {
  const [phase, setPhase] = useState('Inhale');
  const { themeMode } = useContext(LayoutContext);
  const breathingRef = useRef(null);

  useEffect(() => {
    const inhaleDuration = 4 * 1000;
    const holdDuration = 4 * 1000;
    const exhaleDuration = 6 * 1000;

    let inhaleTimer, holdTimer, exhaleTimer;

    const startAnimation = () => {
      setPhase('Inhale');
      inhaleTimer = setTimeout(() => {
        setPhase('Hold');
        holdTimer = setTimeout(() => {
          setPhase('Exhale');
          exhaleTimer = setTimeout(() => {
            // Restart the cycle
            startAnimation();
          }, exhaleDuration);
        }, holdDuration);
      }, inhaleDuration);
    };

    startAnimation();

    return () => {
      clearTimeout(inhaleTimer);
      clearTimeout(holdTimer);
      clearTimeout(exhaleTimer);
    };
  }, []);

  useEffect(() => {
    let angle = themeMode === 'dark' ? 270 : 45; // Start angle based on theme
    const angleIncrement = 0.05; // Adjust for speed
    const intervalDuration = 100; // Adjust for smoothness

    const updateAngle = () => {
      angle = (angle + angleIncrement) % 360;
      if (breathingRef.current) {
        breathingRef.current.style.setProperty('--gradient-angle', `${angle}deg`);
      }
    };

    const angleInterval = setInterval(updateAngle, intervalDuration);

    return () => {
      clearInterval(angleInterval);
    };
  }, [themeMode]);

  return (
    <div className={`breathing-animation dark`} ref={breathingRef}>
      <div className="circle-container">
        <div className="blob" />
        <div className="breathing-text">{phase}</div>
      </div>
    </div>
  );
}

export default BreathingAnimationComponent;