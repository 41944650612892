import axios from "axios";

const backendURI = process.env.REACT_APP_DORO_API_BASE_URI;
const protocol = process.env.REACT_APP_DORO_API_PROTOCOL;
const wsProtocol = process.env.REACT_APP_DORO_API_WS_PROTOCOL;
const baseURL = `${protocol}://${backendURI}`;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

export default axiosInstance;
export { backendURI, wsProtocol};
