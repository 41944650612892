import React, { useState } from "react";
import './ConfirmationPopup.css';
import close from '../../assets/close.svg';
import CircularProgress from '@mui/material/CircularProgress';

function ConfirmationPopup({ onClose, onDelete, onShare, actionType = 'DeleteSession' }) {
    const [email, setEmail] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [blankWarning, setBlankWarning] = useState('');

    // Add email validation function
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setBlankWarning(''); // Clear warning when user types
    };

    const handleShareClick = async () => {
        setBlankWarning(''); // Clear any previous warnings

        if (email.trim() === '') {
            setBlankWarning('Please enter an email address.');
            return;
        } else if (!validateEmail(email.trim())) {
            setBlankWarning('Please enter a valid email address.');
            return;
        }

        setLoading(true);
        setStatusMessage('');
        setShowButtons(false);

        const success = await onShare(email);
        setLoading(false);

        if (success) {
            setStatusMessage('Email has been sent successfully.');
        } else {
            setStatusMessage('Failed to send the email. Please try again.');
            setShowButtons(true);
        }
    };

    const warningMessage = {
        DeleteSession: "Your session and all related data will be permanently deleted and not retained by Doro.",
        DeleteAccount: "Your data and account will be deleted, and Doro will no longer recognize you.",
        ShareSession: "Share this session with your therapist by entering their email below."
    }[actionType];

    return (
        <div className="confirmation-popup-overlay" onClick={(e) => e.target === e.currentTarget && onClose()}>
            <div className="confirmation-popup-content">
                <button className="close-button" onClick={onClose}>
                    <img src={close} alt="Close" />
                </button>

                <p className="confirmation-popup-title">
                    {actionType === 'DeleteSession' && "Delete Session"}
                    {actionType === 'DeleteAccount' && "Delete Account"}
                    {actionType === 'ShareSession' && "Share Session"}
                </p>

                {statusMessage ? (
                    <p className={`confirmation-popup-${statusMessage.includes('successfully') ? 'success' : 'failure'}`}>
                        {statusMessage}
                    </p>
                ) : (
                    <>
                        <p className="confirmation-popup-warning">{warningMessage}</p>

                        {!loading && actionType === 'ShareSession' && (
                            <>
                                <div className={`share-session-input ${loading ? 'loading' : ''}`}>
                                    <input
                                        type="email"
                                        placeholder="Enter recipient's email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        disabled={loading}
                                    />
                                </div>
                                {blankWarning && <p className="error-message">{blankWarning}</p>}
                            </>
                        )}

                        {loading && (
                            <div className="loading-container">
                                <CircularProgress style={{color: '#484F59'}}/>
                            </div>
                        )}

                        {showButtons && (
                            <div className="confirmation-popup-buttons">
                                {actionType === 'ShareSession' ? (
                                    <button
                                        className="confirmation-button share"
                                        onClick={handleShareClick}
                                        disabled={loading}
                                    >
                                        Send
                                    </button>
                                ) : (
                                    <button className="confirmation-button delete" onClick={onDelete}>
                                        {actionType === 'DeleteAccount' ? "Delete Account" : "Delete Session"}
                                    </button>
                                )}
                                <button className="confirmation-button cancel" onClick={onClose}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default ConfirmationPopup;