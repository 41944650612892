import React from 'react';
import Navbar from '../components/Navbar';
import '../styles/NavBar.css';
import {LayoutProvider} from '../context/LayoutContext';
import { SessionProvider } from '../context/SessionContext';

const Layout = ({ children }) => {
  return (
    <LayoutProvider>
      <SessionProvider>
        <div>
          <Navbar />
          <div className="content">
            {children}
          </div>
        </div>
      </SessionProvider>
    </LayoutProvider>
  );
}

export default Layout;