/* Main module for handling WebSocket connection and messages in the Dorochat component. */
import { useEffect, useRef, useCallback, useState } from 'react';
import { backendURI, wsProtocol } from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import getFirebaseIdToken from '../Firebase/getFirebaseIdToken';

// Define action types as constants to avoid typos
const DoroActionTypes = {
    STREAM: 'STREAM',
    NO_FURTHER_ACTION: 'NO_FURTHER_ACTION',
    BREATHING_ACTION: "BREATHING_ACTION",
    // Add new action types here
};

const UserActionTypes = {
    Pause: 'PAUSE',
};

export default function useWebSocket(isChatStarted, setMessages, setInputDisabled, setHasChatEnded) {
    const navigate = useNavigate();
    const websocket = useRef(null);
    const sessionEndedIntentionally = useRef(false);
    const sessionID = useRef(null);
    const elapsed_time = useRef(null);
    const sessionDuration = useRef(null);
    const totalSeconds = useRef(null);
    const [progress, setProgress] = useState(0);
    const [timer, setTimer] = useState(0);
    const errorTimeoutRef = useRef(null);
    const [isConnectionError, setConnectionError] = useState(false);
    const resumeSession = useRef(false);

    const getTokenOrRedirect = async () => {
        const idToken = await getFirebaseIdToken();
        if (!idToken) {
            console.error("Failed to retrieve idToken or no user is authenticated.");
            navigate('/get-started');
            return;
        }
        return idToken;
    }

    // Helper function to manage connection error state with delay
    const setConnectionErrorWithDelay = (isError) => {
        if (isError) {
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
            errorTimeoutRef.current = setTimeout(() => {
                setConnectionError(true);
            }, 5000);
        } else {
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
            setConnectionError(false);
        }
    };

    // Function to send messages via WebSocket
    const sendMessage = useCallback((message, type = "text", action_type=null) => {
        if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
            console.error("WebSocket not connected. Cannot send message.");
            return;
        }

        websocket.current.send(JSON.stringify({ "m_type": type, "content": message, "action_type": action_type }));
        
        if (action_type === UserActionTypes.Pause) {
            return;
        }

        // Update messages state
        if (message !== "" && type === "text") {
            setMessages((messages) => ([
                ...messages,
                { id: Date.now(), text: message, role: "user" },
                {
                    id: "typing-indicator",
                    text: "",
                    role: "assistant",
                    class: "typing-indicator",
                },
            ]));
        } else if (message !== "" && type === "audio") {
            setMessages((messages) => ([
                ...messages,
                {
                    id: "typing-indicator",
                    text: "",
                    role: "assistant",
                    class: "typing-indicator",
                },
            ]));
        }
    }, [setMessages]);

    // Handler functions for different action types
    const handleStreamAction = useCallback((response) => {
        const { role, action } = response;
        const { content } = action;

        if (typeof content !== 'string') {
            console.error('Invalid content in STREAM action:', action);
            return;
        }

        setMessages((messages) => {
            if (messages.length === 0 || messages[messages.length - 1].role !== role) {
                return [
                    ...messages,
                    { id: Date.now(), text: content, role: role },
                    // If the role is "user", add a typing indicator for the assistant
                    ...(role === "user" ? [{
                        id: "typing-indicator",
                        text: "",
                        role: "assistant",
                        class: "typing-indicator"
                    }] : [])
                ];
            } else {
                // Append content to the last message
                return messages.map((message, index) => {
                    if (index === messages.length - 1) {
                        return { ...message, text: message.text + content };
                    }
                    return message;
                });
            }
        });
    }, [setMessages]);

    const handleBreathingAction = useCallback((response) => {
        // You can extract any content if needed from the response
        setMessages((messages) => ([
            ...messages,
            {
                id: Date.now(),
                type: 'breathing', // Add a type to identify it's a breathing action
            },
        ]));
    }, [setMessages]);

    const handleNoFurtherAction = useCallback(() => {
        // Enable input if the assistant has responded
        setInputDisabled(false);
    }, [setMessages]);

    const startSession = async (resume_session=false) => {
        resumeSession.current = resume_session;
        const token = await getTokenOrRedirect();

        websocket.current = new WebSocket(`${wsProtocol}://${backendURI}/chat/ws?token=${token}&resume_session=${resume_session}`);

        websocket.current.onopen = () => {
            sessionID.current = null;
            sessionEndedIntentionally.current = false;
            setConnectionErrorWithDelay(false);
        };

        websocket.current.onerror = (error) => {
            console.error("WebSocket Error: ", error);
            
            setConnectionErrorWithDelay(true);
        };

        websocket.current.onclose = (event) => {
            console.log("WebSocket closed with event: ", event);
            if (!sessionEndedIntentionally.current) {
                setConnectionErrorWithDelay(true);
            }
            setHasChatEnded(true);
        };

        websocket.current.onmessage = receiveMessage;
    };

    // Dispatcher mapping action types to their handlers
    const actionHandlers = useRef({
        [DoroActionTypes.STREAM]: handleStreamAction,
        [DoroActionTypes.NO_FURTHER_ACTION]: handleNoFurtherAction,
        [DoroActionTypes.BREATHING_ACTION]: handleBreathingAction,
        // Add new action handlers here
    }).current;

    // Function to receive and handle messages from the WebSocket
    const receiveMessage = useCallback((event) => {
        const response = JSON.parse(event.data);
        
        // Remove typing indicators
        if (sessionID.current) {
            setMessages((messages) => messages.filter((message) => message.id !== "typing-indicator"));
        }

        // Handle session initialization
        if (!sessionID.current) {
            sessionID.current = response.session_id;
            elapsed_time.current = response.elapsed_time_in_minutes;
            sessionDuration.current = response.session_duration;
            totalSeconds.current = sessionDuration.current * 60;
            setTimer(elapsed_time.current * 60);
            
            if (!resumeSession.current) {
                sendMessage("", "text");
                setInputDisabled(true);
                return;
            }
        } else if (sessionID.current && !response.action) {
            const newMessages = response.map((message) => {
                return {
                    id: message.id,
                    text: message.content,
                    role: message.role,
                };
            });
            setMessages([...newMessages]);
            return;
        }

        const { action } = response;

        // Validate action and action_type
        if (!action || typeof action.action_type !== 'string') {
            console.error('Invalid action or missing action_type:', action);
            return;
        }

        // Dispatch to the appropriate handler
        const handler = actionHandlers[action.action_type];
        if (handler) {
            handler(response);
        } else {
            console.error('Unknown action_type:', action.action_type);
        }
    }, [actionHandlers, sendMessage, setInputDisabled, setMessages]);

    // Effect to manage WebSocket connection lifecycle
    useEffect(() => {    
        const closeWebSocket = (reason = "User left the session.") => {
            if (!websocket.current) return;
            if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
                sessionEndedIntentionally.current = true;
                websocket.current.close(1000, reason);
            }
        };

        window.addEventListener("beforeunload", closeWebSocket);
        window.addEventListener("unload", closeWebSocket);

        return () => {
            window.removeEventListener("beforeunload", closeWebSocket);
            window.removeEventListener("unload", closeWebSocket);
            closeWebSocket("Session unmounted.");
        };

    }, [isChatStarted, receiveMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    // Effect to manage session timer and progress
    useEffect(() => {
        if (!isChatStarted) {
            setTimer(0);
            setProgress(0);
            return;
        }

        const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
                const newTimer = prevTimer + 1;
                if (newTimer >= totalSeconds.current) {
                    clearInterval(intervalId);
                    setInputDisabled(true);
                    if (websocket.current) {
                        sessionEndedIntentionally.current = true;
                        websocket.current.close(1000, "Session ended.");
                    }
                    return totalSeconds.current;
                }
                return newTimer;
            });
        }, 1000); // Update every second

        return () => clearInterval(intervalId);
    }, [isChatStarted, setInputDisabled, totalSeconds]);

    // Update progress whenever timer changes
    useEffect(() => {
        setProgress((timer / totalSeconds.current) * 100);
    }, [timer, totalSeconds]);

    return { sendMessage, startSession, timer, progress, isConnectionError, sessionID };
}
