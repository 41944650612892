import React, { useState, useEffect, useContext } from 'react';
import { Box, Stack } from '@mui/material';
import StartButton from './StartButton.js';
import RecordButton from './RecordButton.js';
import ChatTextInput from './ChatTextInput.js';
import EndChatButton from './EndChatButton.js';
import useWindowSizeListener from '../../hooks/useWindowSizeListener.js';
import { DoroChatContext } from '../../context/DoroChatContext.js';
import { LayoutContext } from '../../context/LayoutContext.js';
import FeedbackPopup from '../dorochat/FeedbackPopup.js';
import { useNavigate } from 'react-router-dom';

const ChatBottomBar = () => {
  const { showTerms } = useContext(DoroChatContext);
  const { isChatStarted, setIsChatStarted } = useContext(LayoutContext);
  const { windowSize } = useWindowSizeListener();
  const [stackWidth, setStackWidth] = useState(null);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (windowSize.deviceType === 'large') {
      setStackWidth('80%');
    } else {
      setStackWidth('90%');
    }
  }, [windowSize]);

  const handleEndChat = () => {
    setIsChatStarted(false);
    setShowFeedbackPopup(true);
  };

  const handleCloseFeedbackPopup = () => {
    setShowFeedbackPopup(false);
    navigate('/profile');
  };

  return (
    !showTerms && (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Stack
          direction='row'
          id='input-area'
          sx={{
            width: stackWidth,
            alignItems: isChatStarted ? 'flex-end' : 'center',
            justifyContent: 'center',
            paddingBottom: '2em',
          }}
        >
          {isChatStarted && <ChatTextInput />}
          <Stack
            direction={windowSize.deviceType === 'mobile' ? 'column' : 'row'}
            justifyContent={isChatStarted ? 'space-between' : 'center'}
            sx={{
              pl: isChatStarted ? '1em' : 0,
            }}
          >
            {isChatStarted && <RecordButton />}
            <StartButton />
            {isChatStarted && windowSize.deviceType === 'large' && (
              <EndChatButton onClick={handleEndChat} />
            )}
          </Stack>
        </Stack>
        {showFeedbackPopup && (
          <FeedbackPopup onClose={handleCloseFeedbackPopup} />
        )}
      </Box>
    )
  );
};

export default ChatBottomBar;
