import React, { useEffect } from "react";
import TopSection from "./TopSection";
import AssessmentCategory from "./AssessmentCategory";
import Footer from "../Footer";
import '../../styles/ResourcePage.css';
import MetaTags from 'react-meta-tags';

function ResourcesPage() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className="mainpage fade-in">
      <MetaTags>
        <title>Questionnaires - Doro: Mental Health Progress & Support</title>
        <meta name="description" content="Discover free, validated mental health questionnaires on Doro by Razroze to help identify early symptoms and support research for improved mental health care." />
        <link rel="canonical" href="https://doro.razroze.ca/resources" />
      </MetaTags>
      <TopSection />
      <AssessmentCategory />
      <Footer />
    </div>
  );
}

export default ResourcesPage;
