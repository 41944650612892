import React, { useEffect, useState, useRef, useContext } from 'react';
import axiosInstance from "../../utils/axiosConfig";
import getFirebaseIdToken from '../../Firebase/getFirebaseIdToken';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SessionContext } from '../../context/SessionContext';

function WordCloud(props) {
    const [imageData, setImageData] = useState(null);
    const { sessionLoading } = props;
    const { lastSessionLoading } = useContext(SessionContext);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (!(sessionLoading || lastSessionLoading)) {
            setLoading(true);
            async function fetchWordCloud() {
                try {
                    const idToken = await getFirebaseIdToken();
                    const response = await axiosInstance.get(`/users/me/wordcloud`, {
                        headers: {
                            Authorization: `${idToken}`,
                        },
                    });
                    if (response && response.data) {
                        setImageData(response.data.wordcloud);
                    }
                } catch (error) {
                    console.error('Error fetching word cloud:', error);
                } finally {
                    setLoading(false);
                }
            }
            fetchWordCloud();
        }
    }, [sessionLoading, lastSessionLoading]);

    if (!(sessionLoading || lastSessionLoading) && !imageData) {
        return (
            <div className="spinner-wrapper">
                { (sessionLoading || lastSessionLoading || loading) ? 
                  <div className="session-indicator">Loading </div> :
                  <button className="session-button"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  onClick={() => navigate("/doro-test-start")}>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        START YOUR SESSION
                    </Link>
                </button> }
            </div>
        );
    }

    if (sessionLoading || lastSessionLoading || loading) {
        return (
            <div className="spinner-wrapper">
                <div className="session-indicator">Loading </div>
            </div>
        );
    }

    return (
        <img
            src={`data:image/png;base64,${imageData}`}
            alt="Word Cloud"
            style={{ width: '55%', height: 'auto', padding: '40px 10px' }}
        />
    );
}

export default WordCloud;