import React, { useRef, useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import DoroCard from "../DoroCard";
import "../../styles/AssessmentCategoryStyles.css";
import { Button, CircularProgress } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { formatCategories } from "../../utils/categoryUtils";
import { pageViewGA } from "../../utils/analyticsEvents";

const AssessmentCategory = () => {
  const [categories, setCategories] = useState([]);
  const [currentSetIndices, setCurrentSetIndices] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const categoryRefs = useRef([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        // Check if categories are available in localStorage
        const cachedCategories = localStorage.getItem("categories");
        if (cachedCategories) {
          setCategories(JSON.parse(cachedCategories));
          setLoading(false);
          return;
        }

        const response = await axiosInstance.get("/ptests/");
        const data = response.data;
        const formattedCategories = formatCategories(data);
        setCategories(formattedCategories);
        localStorage.setItem("categories", JSON.stringify(formattedCategories));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (e) => {
    if (e.key !== "Enter") return;

    const currentSearchTerm = e.target.value.trim().toLowerCase();
    setSearchTerm(currentSearchTerm);
    pageViewGA(currentSearchTerm);

    // Try to find a category that matches the search term
    let targetCategoryIndex = categories.findIndex((category) =>
      category.bannerLabel.toLowerCase().includes(currentSearchTerm)
    );

    // If no category is found, search for a specific assessment abbreviation
    if (targetCategoryIndex === -1) {
      targetCategoryIndex = categories.findIndex((category) =>
        category.assessments.some(
          (assessment) =>
            assessment.abbreviation.toLowerCase().includes(currentSearchTerm)
        )
      );
    }

    // If no category is found, search for a specific assessment description
    if (targetCategoryIndex === -1) {
      targetCategoryIndex = categories.findIndex((category) =>
        category.assessments.some(
          (assessment) =>
            assessment.description.toLowerCase().includes(currentSearchTerm)
        )
      );
    }

    if (
      targetCategoryIndex !== -1 &&
      categoryRefs.current[targetCategoryIndex]
    ) {
      categoryRefs.current[targetCategoryIndex].scrollIntoView({
        behavior: "smooth",
      });
      setSearchTerm("");
    }
  };

  const handleNextSet = (categoryIndex) => {
    setCurrentSetIndices((prevIndices) => ({
      ...prevIndices,
      [categoryIndex]: (prevIndices[categoryIndex] || 0) + 4,
    }));
  };

  const handlePrevSet = (categoryIndex) => {
    setCurrentSetIndices((prevIndices) => ({
      ...prevIndices,
      [categoryIndex]: Math.max(0, (prevIndices[categoryIndex] || 0) - 4),
    }));
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "340px",
          }}
        >
          <CircularProgress sx={{ color: "#768094" }} />
        </div>
      ) : (
        <div style={{ position: "relative", overflowX: "hidden"}}>
          {/* Search Bar */}
          {window.innerWidth > 1030 &&
            <input
              placeholder="SEARCH"
              className="search-category"
              label="Search for category"
              variant="standard"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleSearchChange}
            />}
          <div className="categories-container">
            {categories.map((category, categoryIndex) => {
              const numSlides = Math.ceil(category.assessments.length / 4);
              const currentSlide = Math.floor(
                (currentSetIndices[categoryIndex] || 0) / 4
              );

              return (
                <div
                  key={categoryIndex}
                  className={`category-container ${
                    categoryIndex === activeCategory ? "active" : ""
                  }`}
                  ref={(el) => (categoryRefs.current[categoryIndex] = el)}
                >
                  <img
                    src={category.bannerSrc}
                    alt="Category Banner"
                    className="banner"
                  />
                  <div className="label-container">{category.bannerLabel}</div>

                  <div className="cards-container">
                    {window.innerWidth > 1030 ? category.assessments
                      .slice(
                        currentSetIndices[categoryIndex] || 0,
                        (currentSetIndices[categoryIndex] || 0) + 4
                      )
                      .map((item, index) => (
                        <DoroCard
                          key={index}
                          title={item.abbreviation}
                          description={item.summary}
                          questions={item.statements}
                          time={item.duration}
                          id={item.id}
                        />
                      )) : 
                      category.assessments.map((item, index) => (
                        <DoroCard
                          key={index}
                          title={item.abbreviation}
                          description={item.summary}
                          questions={item.statements}
                          time={item.duration}
                          id={item.id}
                        />
                      ))
                      }
                  </div>

                  {/* Individual Category Slider Controls and Navigation Bubbles */}
                  {window.innerWidth > 1030 &&
                    <div className="slider-controls">
                    <Button
                      className="slider-button prev"
                      onClick={() => handlePrevSet(categoryIndex)}
                      disabled={(currentSetIndices[categoryIndex] || 0) <= 0}
                    >
                      <span className="control-text">Last</span> <ArrowBack />
                    </Button>

                    {/* Navigation Bubbles */}
                    <div className="slider-dots">
                      {Array.from({ length: numSlides }).map((_, dotIndex) => (
                        <div
                          key={dotIndex}
                          className={`slider-dot ${dotIndex === currentSlide ? "active" : ""
                            }`}
                        ></div>
                      ))}
                    </div>

                    <Button
                      className="slider-button next"
                      onClick={() => handleNextSet(categoryIndex)}
                      disabled={
                        (currentSetIndices[categoryIndex] || 0) >=
                        category.assessments.length - 4
                      }
                    >
                      <span className="control-text">Next</span>{" "}
                      <ArrowForward />
                    </Button>
                  </div>
                  }
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default AssessmentCategory;
