import { getAuth, onAuthStateChanged } from 'firebase/auth';

const getFirebaseIdToken = async () => {
  const auth = getAuth();

  if (auth.currentUser) {
    // User is already signed in
    try {
      const idToken = await auth.currentUser.getIdToken();
      return idToken;
    } catch (error) {
      console.error("Failed to get token:", error);
      return null;
    }
  } else {
    // User is not signed in yet, wait for auth state to change
    return new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const idToken = await user.getIdToken();
            resolve(idToken);
          } catch (error) {
            console.error("Failed to get token:", error);
            resolve(null);
          }
        } else {
          console.error("No user is currently authenticated.");
          resolve(null);
        }
        unsubscribe(); // Clean up the listener
      });
    });
  }
};

export default getFirebaseIdToken;
