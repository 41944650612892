import React from "react";
import "../styles/DoroChat.css";


const DisclaimerPopup = ({handleClose}) => {
    return (
        <div className="terms-popup">
            <div className="terms-content">
                <div className="scroll-wrapper">
                    <div className="disclaimer-text-wrapper">
                        <h2 style={{ fontSize: "28px" }}>Disclaimer</h2>
                        <p style={{ fontSize: "19px", paddingRight: "20px" }}>
                            Doro is designed to provide supportive dialogues and access to
                            validated mental health questionnaires, fostering a proactive
                            approach towards mental health awareness. However, it is not a
                            substitute for professional medical advice, diagnosis, or
                            treatment. The information and resources provided by Doro
                            should not be used as a basis for making diagnostic or
                            treatment decisions.
                        </p>
                        <p style={{ fontSize: "19px" }}>
                            If you or someone you know are in crisis or think you may have
                            an emergency, please use the emergency call button on this
                            page. It's imperative to consult with a qualified healthcare
                            provider or mental health professional for personalized advice
                            and treatment.
                        </p>
                        <p style={{ fontSize: "19px" }}>
                            Please note, the current version of Doro is intended for
                            individuals 18 years of age or older.
                        </p>
                        <p style={{ fontSize: "19px" }}>
                            To ensure you achieve maximum effectiveness with Doro, we
                            may send you reminder emails up to once per week to encourage
                            regular sessions.
                        </p>
                        <p style={{ fontSize: "19px" }}>
                            At its core, Doro's sole aspiration is to pave the way for
                            early intervention in psychological well-being, nurturing a
                            journey towards self-awareness and proactive mental health
                            care.
                        </p>
                        <button onClick={handleClose} className="terms-button" style={{ marginBottom: "30px" }}>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DisclaimerPopup;