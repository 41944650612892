import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../utils/axiosConfig";
import getFirebaseIdToken from '../Firebase/getFirebaseIdToken';

const SessionContext = createContext(null);

const SessionStatus = {
    FINISHED: 2,
    PAUSED: 5,
    PROCESSING: 6,
};

const SessionProvider = ({ children }) => {
  const [isChatPaused, setIsChatPaused] = useState(false);
  const [lastSessionData, setLastSessionData] = useState(null);
  const [lastSessionLoading, setLastSessionLoading] = useState(true);
  const navigate = useNavigate();

  const fetchLastSession = useCallback(async () => {
    const idToken = await getFirebaseIdToken();
    setLastSessionLoading(true);

    if (!idToken) {
      navigate('/get-started');
      return null;
    }
    try {
      const response = await axiosInstance.get(`/users/me/last-session`, {
        headers: { Authorization: `${idToken}` },
      });
      if (response.status === 200) {
        setLastSessionData(response.data);
        if (response.data.status === SessionStatus.PAUSED) {
          setIsChatPaused(true);
        } else if (response.data.status === SessionStatus.FINISHED) {
          setIsChatPaused(false);
        }
        setLastSessionLoading(false);
        return response.data;
      }
      setLastSessionLoading(false);
    } catch (error) {
      console.error('Error fetching last session data:', error);
      setLastSessionData(null); // Reset last session data
      if (error.response && error.response.status === 404) {
        setIsChatPaused(false); // No resumable session
        setLastSessionLoading(false);
      }
    }
    return null;
  }, [navigate]);

  const value = {
    isChatPaused,
    setIsChatPaused,
    lastSessionData,
    lastSessionLoading,
    fetchLastSession,
  };

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export { SessionProvider, SessionStatus, SessionContext };