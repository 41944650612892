import ReactGA from "react-ga4";

ReactGA.initialize("G-GRMF60R0YM");

export const loginGA = () => {
  ReactGA.gtag("event", "login", {
    method: "Google"
  });
};

export const signUpGA = () => {
  ReactGA.gtag("event", "sign_up", {
    method: "Google"
  });
};

export const pricingView = () => {
  ReactGA.gtag("event", "view_item", {
    method: "Google"
  });
};

export const pageViewGA = (searchTerm) => {
  ReactGA.gtag("event", "search", {
    search_term: searchTerm
  });
};
