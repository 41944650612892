import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box } from '@mui/material';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import '../../styles/DoroChat.css';
import FeedbackPopup from './FeedbackPopup';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from './ErrorPopup';
import TermsPopup from './TermsPopup';
import DoroChatContent from './DoroChatContent.js';
import { DoroChatContext } from '../../context/DoroChatContext.js';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import { LayoutContext } from '../../context/LayoutContext.js';
import { SessionContext } from '../../context/SessionContext.js';
import ChatBottomBar from './ChatBottomBar.js';
import ThemeSwitch from './ThemeSwitch.js';
import PauseButton from './PauseButton.js';
import LoadingSpinner from '../common/LoadingSpinner.js';

function DoroChat() {
  const navigate = useNavigate();
  const {
    remainingSessions,
    timer,
    progress,
    isConnectionError,
    sessionID,
    showTerms,
    handleAgree,
    showFeedbackPopup,
    setShowFeedbackPopup,
  } = useContext(DoroChatContext);
  const { windowSize } = useWindowSizeListener();
  const { setIsChatStarted, themeMode, isChatStarted, hasChatEnded } = useContext(LayoutContext);
  const {lastSessionLoading, fetchLastSession, isChatPaused} = useContext(SessionContext);
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    fetchLastSession();
  }, [fetchLastSession]);

  const handleClosePopup = () => {
    setShowFeedbackPopup(false); // Directly set to false for closing
    setIsChatStarted(false);
    navigate('/profile');
  };

  const handleIntroFadeOut = () => {
    document.querySelector('.info-text-wrapper')?.classList.add('fade-out');
    setTimeout(() => setShowIntro(false), 300);
    setTimeout(() => document.querySelector('.disclaimer-text-wrapper')?.classList.add('fade-in'), 300);
  };

  useEffect(() => {
    const chatSection = document.querySelector('.chat-section');
    if (!chatSection) {
      return;
    }

    const adjustMessageOpacity = () => {
      const chatHeight = chatSection.offsetHeight;
      const messages = document.querySelectorAll('.chat-bubble');

      messages.forEach((message, index) => {
        const isLastMessage = index === messages.length - 1;
        if (isLastMessage) {
          message.style.opacity = 1; // Ensure the last message is fully opaque
        } else {
          const messageRect = message.getBoundingClientRect();
          const chatRect = chatSection.getBoundingClientRect();
          let opacity = 1;

          if (messageRect.bottom < chatRect.bottom) {
            const distanceFromTop = messageRect.top - chatRect.top;
            opacity = distanceFromTop / (chatHeight * 0.5);
            opacity = opacity < 0.5 ? opacity : 1;
            opacity = Math.min(Math.max(opacity, 0), 1);
          }
          message.style.opacity = opacity;
        }
      });
    };
    
    chatSection.addEventListener('scroll', adjustMessageOpacity);
    adjustMessageOpacity(); // Initial adjustment
    return () => chatSection.removeEventListener('scroll', adjustMessageOpacity);
  }, []);

  useEffect(() => {
    // When the component mounts, prevent the body from scrolling
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Box className={themeMode === 'light' ? 'lightmode fade-in' : 'chatpage fade-in'}>
      {showTerms && !isConnectionError && (
        <TermsPopup
          showIntro={showIntro}
          handleIntroFadeOut={handleIntroFadeOut}
          handleAgree={handleAgree}
        />
      )}
      {showFeedbackPopup && (
        <FeedbackPopup onClose={handleClosePopup}/>
      )}
      {isConnectionError && (
        <ErrorPopup to='/profile' message='Sorry, there seems to be something wrong. Please try again.' />
      )}
      {remainingSessions === 0 && (
        <ErrorPopup
          to='/profile'
          message='Sorry, you have no remaining sessions left. Please purchase a session package to continue.'
        />
      )}

      <div
        className={`chat-wrapper ${showTerms ? 'blur' : ''}`}
        style={{ height: windowSize?.height }}
      >
        <Box 
          direction='row'
          sx={{    
            position: 'absolute',
            top: windowSize.deviceType !== 'mobile' ? '11.5%' : '12%',
            width: '100%',
            display: 'flex',               
            flexDirection: 'row',           
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {}
          {isChatStarted && <PauseButton />}
          <ThemeSwitch />
        </Box>
        {isChatStarted && !hasChatEnded && 
        <div className='timer-display'>
          <CircularProgressWithLabel value={timer} progress={progress} />
        </div>}
        {hasChatEnded &&
          <div className='chat-ended' style={{color: themeMode==='dark' ? 'white' : '#484F59'}}>
            Session<br />{isChatPaused? 'Paused' : 'ended'}
          </div>
        }
        <div className='animation-wrapper'></div>
        <DoroChatContent />
        {lastSessionLoading && <LoadingSpinner />}
        {!lastSessionLoading && <ChatBottomBar />}
      </div>
    </Box>
  );
}

export default DoroChat;
